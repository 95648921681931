export const Colors = {
  deckA: "#00d0ff",
  deckAGlow: "rgba(0, 208, 255, 0.4)",
  deckbGlow: "rgba(255, 59, 199, 0.4)",
  deckB: "#ff3bc7",
  darkGreyBackground: "#111111",
  knobBackground: "#353535",
  mainBackground: "#333333",
  dirtyBrown: "rgba(38, 19, 16, 0.5)",
  jogWheelBackground: "#171717",
  darkBorder: "#181818;",
  orange: "#ffa600",
  orangeGlow: "rgba(255, 166, 0, 0.4)",
  tableBackground: "#2b2b2b",
  dullOrange: "rgba(189, 132, 26, 0.6)",
  lightGrey: "#9e9e9e",
  purple: "#8000ff",
  meter: "#80ff00",
  meterPeak: "#ff4000",
  black: "black",
  turquoise: "#23c471",
  turquoiseGlow: "rgba(35, 196, 113, 0.4)",
  white: "#ffffff",
};
